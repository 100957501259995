define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vG7LHOj1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"select\",\"selected\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"selectedItemComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"selectedItemComponent\"]]],[[\"extra\",\"option\",\"select\"],[[28,\"readonly\",[[24,[\"extra\"]]],null],[28,\"readonly\",[[24,[\"select\",\"selected\"]]],null],[28,\"readonly\",[[24,[\"select\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-selected-item\"],[8],[14,1,[[24,[\"select\",\"selected\"]],[24,[\"select\"]]]],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"and\",[[24,[\"allowClear\"]],[28,\"not\",[[24,[\"select\",\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-clear-btn\"],[11,\"onmousedown\",[28,\"action\",[[23,0,[]],\"clear\"],null]],[11,\"ontouchstart\",[28,\"action\",[[23,0,[]],\"clear\"],null]],[8],[0,\"×\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[24,[\"placeholderComponent\"]]],[[\"placeholder\"],[[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"span\",true],[10,\"class\",\"ember-power-select-status-icon\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });

  _exports.default = _default;
});