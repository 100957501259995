define("ember-cli-notifications/styles/components/notification-container", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "--container-position": "10px",
    "--container-width": "80%",
    "--container-max-with": "400px",
    "c-notification__container": "_c-notification__container_rc8i2a",
    "c-notification__container--top": "_c-notification__container--top_rc8i2a _c-notification__container_rc8i2a",
    "c-notification__container--top-left": "_c-notification__container--top-left_rc8i2a _c-notification__container_rc8i2a",
    "c-notification__container--top-right": "_c-notification__container--top-right_rc8i2a _c-notification__container_rc8i2a",
    "c-notification__container--bottom": "_c-notification__container--bottom_rc8i2a _c-notification__container_rc8i2a",
    "c-notification__container--bottom-left": "_c-notification__container--bottom-left_rc8i2a _c-notification__container_rc8i2a",
    "c-notification__container--bottom-right": "_c-notification__container--bottom-right_rc8i2a _c-notification__container_rc8i2a"
  };
});