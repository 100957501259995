define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2O7pbHFG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"searchEnabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ember-power-select-search\"],[8],[0,\"\\n    \"],[7,\"input\",true],[10,\"autocomplete\",\"off\"],[10,\"autocorrect\",\"off\"],[10,\"autocapitalize\",\"off\"],[10,\"spellcheck\",\"false\"],[10,\"role\",\"combobox\"],[10,\"class\",\"ember-power-select-search-input\"],[11,\"value\",[24,[\"select\",\"searchText\"]]],[11,\"aria-controls\",[22,\"listboxId\"]],[11,\"placeholder\",[22,\"searchPlaceholder\"]],[11,\"oninput\",[22,\"onInput\"]],[11,\"onfocus\",[22,\"onFocus\"]],[11,\"onblur\",[22,\"onBlur\"]],[11,\"onkeydown\",[28,\"action\",[[23,0,[]],\"onKeydown\"],null]],[10,\"type\",\"search\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
    }
  });

  _exports.default = _default;
});