define("ember-cli-notifications/templates/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hv6ekwYZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"unbound\",[[24,[\"__styles__\",\"c-notification__icon\"]]],null]]]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"notificationIcon\"]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[[28,\"unbound\",[[24,[\"__styles__\",\"c-notification__content\"]]],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"notification\",\"htmlContent\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,[\"notification\",\"message\"]],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,[\"notification\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"div\",false],[12,\"title\",\"Dismiss this notification\"],[12,\"class\",[29,[[28,\"unbound\",[[24,[\"__styles__\",\"c-notification__close\"]]],null]]]],[3,\"action\",[[23,0,[]],\"removeNotification\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"closeIcon\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"notification\",\"autoClear\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"style\",[22,\"notificationClearDuration\"]],[11,\"class\",[29,[[28,\"unbound\",[[24,[\"__styles__\",\"c-notification__countdown\"]]],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-notifications/templates/components/notification-message.hbs"
    }
  });

  _exports.default = _default;
});