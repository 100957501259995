define('ember-cli-notifications/services/notification-messages-service', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var notificationAssign = Ember.assign || Ember.merge;
  var globals = _emberGetConfig.default['ember-cli-notifications'] || {}; // Import app config object

  var NotificationMessagesService = Ember.ArrayProxy.extend({
    content: Ember.A(),

    addNotification: function addNotification(options) {
      // If no message is set, throw an error
      if (!options.message) {
        throw new Error("No notification message set");
      }

      var notification = Ember.Object.create({
        message: options.message,
        type: options.type || 'info',
        autoClear: Ember.isEmpty(options.autoClear) ? Ember.getWithDefault(globals, 'autoClear', false) : options.autoClear,
        clearDuration: options.clearDuration || Ember.getWithDefault(globals, 'clearDuration', 5000),
        onClick: options.onClick,
        htmlContent: options.htmlContent || false,
        cssClasses: options.cssClasses
      });

      this.pushObject(notification);

      if (notification.autoClear) {
        notification.set('remaining', notification.get('clearDuration'));

        this.setupAutoClear(notification);
      }

      return notification;
    },
    error: function error(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'error'
      }, options));
    },
    success: function success(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'success'
      }, options));
    },
    info: function info(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'info'
      }, options));
    },
    warning: function warning(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'warning'
      }, options));
    },
    removeNotification: function removeNotification(notification) {
      var _this = this;

      if (!notification) {
        return;
      }

      notification.set('dismiss', true);

      // Delay removal from DOM for dismissal animation
      Ember.run.later(this, function () {
        _this.removeObject(notification);
      }, 500);
    },
    setupAutoClear: function setupAutoClear(notification) {
      var _this2 = this;

      notification.set('startTime', Date.now());

      var timer = Ember.run.later(this, function () {
        // Hasn't been closed manually
        if (_this2.indexOf(notification) >= 0) {
          _this2.removeNotification(notification);
        }
      }, notification.get('remaining'));

      notification.set('timer', timer);
    },
    pauseAutoClear: function pauseAutoClear(notification) {
      Ember.run.cancel(notification.get('timer'));

      var elapsed = Date.now() - notification.get('startTime');
      var remaining = notification.get('clearDuration') - elapsed;

      notification.set('remaining', remaining);
    },
    clearAll: function clearAll() {
      var _this3 = this;

      this.get('content').forEach(function (notification) {
        _this3.removeNotification(notification);
      });

      return this;
    },
    setDefaultAutoClear: function setDefaultAutoClear(autoClear) {
      Ember.set(globals, 'autoClear', autoClear);
    },
    setDefaultClearDuration: function setDefaultClearDuration(clearDuration) {
      Ember.set(globals, 'clearDuration', clearDuration);
    }
  });

  NotificationMessagesService.reopenClass({
    isServiceFactory: true
  });

  exports.default = NotificationMessagesService;
});