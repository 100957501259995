define("ember-cp-validations/validators/number", ["exports", "ember-cp-validations/-private/ember-validator"], function (_exports, _emberValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validates that your attributes have only numeric values.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('number') // Simple check if the value is a number
   *  validator('number', {
   *    allowString: true,
   *    integer: true,
   *    gt: 5,
   *    lte: 100
   *  })
   *  ```
   *
   *  @class Number
   *  @module Validators
   *  @extends Base
   */
  var _default = _emberValidator.default.extend({
    _evType: 'number'
  });

  _exports.default = _default;
});