define("ember-cp-validations/validators/exclusion", ["exports", "ember-cp-validations/-private/ember-validator"], function (_exports, _emberValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validates that the attributes’ values are not included in a given list. All comparisons are done using strict equality so type matters! For range, the value type is checked against both lower and upper bounds for type equality.
   *
   *  ## Examples:
   *
   *  ```javascript
   *  validator('exclusion', {
   *    in: ['Admin', 'Super Admin']
   *  })
   *  validator('exclusion', {
   *    range: [0, 5] // Cannot be between 0 (inclusive) to 5 (inclusive)
   *  })
   *  ```
   *
   *  @class Exclusion
   *  @module Validators
   *  @extends Base
   */
  var _default = _emberValidator.default.extend({
    _evType: 'exclusion'
  });

  _exports.default = _default;
});